import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DatePickerComponent = (props) => {

  const {value, onChange, minDate, className, showWeekPicker} = props;

  return (
      <DatePicker
        selected={value}
        onChange={onChange}
        dateFormat={(showWeekPicker) ? "w-yyyy":"yyyy-MM-dd"} // You can customize the date format
        className={`${(className) ? className : "fs-13 form-control"}`}
        minDate={minDate && new Date()}
        showYearDropdown
        scrollableYearDropdown
        // yearDropdownItemNumber={-5}
        isClearable
        showWeekPicker={(showWeekPicker) ? true : false}
        showWeekNumbers={(showWeekPicker) ? true : false}
        calendarStartDay={1}
      />
  );
};

export default DatePickerComponent;

import React from 'react'
import { Link } from 'react-router-dom'
import Accordion from '../../../components/Accordion'

export default function StaffSettings2() {
  return (
    <div className="tab-pane fade active show" id="staffSetting-tab-pane" role="tabpanel" aria-labelledby="staffSetting-tab" tabIndex="0">
        <div className="card border-0 boxShadow">
            <div className="card-body">
                <div className="row">
                    <div className="col-md-12 mb-3 text-md-end">
                        <Link className='ss_btn'>Save Setting</Link>
                    </div>
                    <div className="col-md-12 mb-3">
                        
                        {/* REGULAR PARK TIMING */}
                        <Accordion id={1} title="Regular Park Timing">
                            <div className="ss-table table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th className="text-center">Events</th>
                                            <th className="text-center">Mon</th>
                                            <th className="text-center">Tue</th>
                                            <th className="text-center">Wed</th>
                                            <th className="text-center">Thr</th>
                                            <th className="text-center">Fri</th>
                                            <th className="text-center">Sat</th>
                                            <th className="text-center">Sun</th>
                                        </tr>
                                        <tr>
                                            <th></th>
                                            <th>
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th className="border-0">Open</th>
                                                            <th className="border-0">Close</th>
                                                        </tr>
                                                    </thead>
                                                </table>
                                            </th>
                                            <th>
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th className="border-0">Open</th>
                                                            <th className="border-0">Close</th>
                                                        </tr>
                                                    </thead>
                                                </table>
                                            </th>
                                            <th>
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th className="border-0">Open</th>
                                                            <th className="border-0">Close</th>
                                                        </tr>
                                                    </thead>
                                                </table>
                                            </th>
                                            <th>
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th className="border-0">Open</th>
                                                            <th className="border-0">Close</th>
                                                        </tr>
                                                    </thead>
                                                </table>
                                            </th>
                                            <th>
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th className="border-0">Open</th>
                                                            <th className="border-0">Close</th>
                                                        </tr>
                                                    </thead>
                                                </table>
                                            </th>
                                            <th>
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th className="border-0">Open</th>
                                                            <th className="border-0">Close</th>
                                                        </tr>
                                                    </thead>
                                                </table>
                                            </th>
                                            <th>
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th className="border-0">Open</th>
                                                            <th className="border-0">Close</th>
                                                        </tr>
                                                    </thead>
                                                </table>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Park Timing</td>
                                            <td>
                                                <table className="table table-borderless">
                                                    <tbody>
                                                        <tr>
                                                            <td><input type="text" className="form-control w-55" /></td>
                                                            <td><input type="text" className="form-control w-55" /></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td>
                                                <table className="table table-borderless">
                                                    <tbody>
                                                        <tr>
                                                            <td><input type="text" className="form-control w-55" /></td>
                                                            <td><input type="text" className="form-control w-55" /></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td>
                                                <table className="table table-borderless">
                                                    <tbody>
                                                        <tr>
                                                            <td><input type="text" className="form-control w-55" /></td>
                                                            <td><input type="text" className="form-control w-55" /></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td>
                                                <table className="table table-borderless">
                                                    <tbody>
                                                        <tr>
                                                            <td><input type="text" className="form-control w-55" /></td>
                                                            <td><input type="text" className="form-control w-55" /></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td>
                                                <table className="table table-borderless">
                                                    <tbody>
                                                        <tr>
                                                            <td><input type="text" className="form-control w-55" /></td>
                                                            <td><input type="text" className="form-control w-55" /></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td>
                                                <table className="table table-borderless">
                                                    <tbody>
                                                        <tr>
                                                            <td><input type="text" className="form-control w-55" /></td>
                                                            <td><input type="text" className="form-control w-55" /></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td>
                                                <table className="table table-borderless">
                                                    <tbody>
                                                        <tr>
                                                            <td><input type="text" className="form-control w-55" /></td>
                                                            <td><input type="text" className="form-control w-55" /></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>                                                                                        
                                    </tbody>
                                </table>
                            </div>
                        </Accordion>

                        <br />
                        {/* PRE OPENING EVENT */}
                        <Accordion id={2} title="Pre Opening Event">
                            <div className="ss-table table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th className="td-bg-color">
                                            </th>
                                            <th className="text-center">Staff Required</th>
                                        </tr>
                                        <tr>
                                            <th className="td-bg-color">
                                                <table className="table table-borderless">
                                                    <thead>
                                                        <tr>
                                                            <th className="td-bg-color">Pre Opening Event</th>
                                                            <th className="text-center w-55 td-bg-color">Open Time</th>
                                                            <th className="text-center w-55 td-bg-color">Close Time</th>
                                                        </tr>
                                                    </thead>
                                                </table>
                                            </th>
                                            <th>
                                                <table className="table table-borderless">
                                                    <thead>
                                                        <tr>
                                                            <th className="text-center w-70">Cafe</th>
                                                            <th className="text-center w-70">Front Desk</th>
                                                            <th className="text-center w-70">Jump Patrol</th>
                                                            <th className="text-center w-70">Lead</th>
                                                            <th className="text-center w-70">Manager</th>
                                                            <th className="text-center w-70">Park Assistant</th>
                                                        </tr>
                                                    </thead>
                                                </table>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table className="table table-borderless">
                                                    <tbody><tr>
                                                        <td>
                                                            <div className="form-check w-150">
                                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                                                                <label className="form-check-label" for="flexCheckChecked">
                                                                    SUN
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td><input type="text" className="form-control w-55" /></td>
                                                        <td><input type="text" className="form-control w-55" /></td>
                                                    </tr>
                                                </tbody></table>
                                            </td>
                                            <td>
                                                <table className="table table-borderless">
                                                    <tbody><tr>
                                                        <td><input type="text" className="form-control w-70" /></td>
                                                        <td><input type="text" className="form-control w-70" /></td>
                                                        <td><input type="text" className="form-control w-70" /></td>
                                                        <td><input type="text" className="form-control w-70" /></td>
                                                        <td><input type="text" className="form-control w-70" /></td>
                                                        <td><input type="text" className="form-control w-70" /></td>
                                                    </tr>
                                                </tbody></table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <table className="table table-borderless">
                                                    <tbody><tr>
                                                        <td>
                                                            <div className="form-check w-150">
                                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                                                                <label className="form-check-label" for="flexCheckChecked">
                                                                    MON
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td><input type="text" className="form-control w-55" /></td>
                                                        <td><input type="text" className="form-control w-55" /></td>
                                                    </tr>
                                                </tbody></table>
                                            </td>
                                            <td>
                                                <table className="table table-borderless">
                                                    <tbody><tr>
                                                        <td><input type="text" className="form-control w-70" /></td>
                                                        <td><input type="text" className="form-control w-70" /></td>
                                                        <td><input type="text" className="form-control w-70" /></td>
                                                        <td><input type="text" className="form-control w-70" /></td>
                                                        <td><input type="text" className="form-control w-70" /></td>
                                                        <td><input type="text" className="form-control w-70" /></td>
                                                    </tr>
                                                </tbody></table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <table className="table table-borderless">
                                                    <tbody><tr>
                                                        <td>
                                                            <div className="form-check w-150">
                                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                                                                <label className="form-check-label" for="flexCheckChecked">
                                                                    TUE
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td><input type="text" className="form-control w-55" /></td>
                                                        <td><input type="text" className="form-control w-55" /></td>
                                                    </tr>
                                                </tbody></table>
                                            </td>
                                            <td>
                                                <table className="table table-borderless">
                                                    <tbody><tr>
                                                        <td><input type="text" className="form-control w-70" /></td>
                                                        <td><input type="text" className="form-control w-70" /></td>
                                                        <td><input type="text" className="form-control w-70" /></td>
                                                        <td><input type="text" className="form-control w-70" /></td>
                                                        <td><input type="text" className="form-control w-70" /></td>
                                                        <td><input type="text" className="form-control w-70" /></td>
                                                    </tr>
                                                </tbody></table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <table className="table table-borderless">
                                                    <tbody><tr>
                                                        <td>
                                                            <div className="form-check w-150">
                                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                                                                <label className="form-check-label" for="flexCheckChecked">
                                                                    WED
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td><input type="text" className="form-control w-55" /></td>
                                                        <td><input type="text" className="form-control w-55" /></td>
                                                    </tr>
                                                </tbody></table>
                                            </td>
                                            <td>
                                                <table className="table table-borderless">
                                                    <tbody><tr>
                                                        <td><input type="text" className="form-control w-70" /></td>
                                                        <td><input type="text" className="form-control w-70" /></td>
                                                        <td><input type="text" className="form-control w-70" /></td>
                                                        <td><input type="text" className="form-control w-70" /></td>
                                                        <td><input type="text" className="form-control w-70" /></td>
                                                        <td><input type="text" className="form-control w-70" /></td>
                                                    </tr>
                                                </tbody></table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <table className="table table-borderless">
                                                    <tbody><tr>
                                                        <td>
                                                            <div className="form-check w-150">
                                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                                                                <label className="form-check-label" for="flexCheckChecked">
                                                                    THR
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td><input type="text" className="form-control w-55" /></td>
                                                        <td><input type="text" className="form-control w-55" /></td>
                                                    </tr>
                                                </tbody></table>
                                            </td>
                                            <td>
                                                <table className="table table-borderless">
                                                    <tbody><tr>
                                                        <td><input type="text" className="form-control w-70" /></td>
                                                        <td><input type="text" className="form-control w-70" /></td>
                                                        <td><input type="text" className="form-control w-70" /></td>
                                                        <td><input type="text" className="form-control w-70" /></td>
                                                        <td><input type="text" className="form-control w-70" /></td>
                                                        <td><input type="text" className="form-control w-70" /></td>
                                                    </tr>
                                                </tbody></table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <table className="table table-borderless">
                                                    <tbody><tr>
                                                        <td>
                                                            <div className="form-check w-150">
                                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                                                                <label className="form-check-label" for="flexCheckChecked">
                                                                    FRI
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td><input type="text" className="form-control w-55" /></td>
                                                        <td><input type="text" className="form-control w-55" /></td>
                                                    </tr>
                                                </tbody></table>
                                            </td>
                                            <td>
                                                <table className="table table-borderless">
                                                    <tbody><tr>
                                                        <td><input type="text" className="form-control w-70" /></td>
                                                        <td><input type="text" className="form-control w-70" /></td>
                                                        <td><input type="text" className="form-control w-70" /></td>
                                                        <td><input type="text" className="form-control w-70" /></td>
                                                        <td><input type="text" className="form-control w-70" /></td>
                                                        <td><input type="text" className="form-control w-70" /></td>
                                                    </tr>
                                                </tbody></table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <table className="table table-borderless">
                                                    <tbody><tr>
                                                        <td>
                                                            <div className="form-check w-150">
                                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                                                                <label className="form-check-label" for="flexCheckChecked">
                                                                    SAT
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td><input type="text" className="form-control w-55" /></td>
                                                        <td><input type="text" className="form-control w-55" /></td>
                                                    </tr>
                                                </tbody></table>
                                            </td>
                                            <td>
                                                <table className="table table-borderless">
                                                    <tbody><tr>
                                                        <td><input type="text" className="form-control w-70" /></td>
                                                        <td><input type="text" className="form-control w-70" /></td>
                                                        <td><input type="text" className="form-control w-70" /></td>
                                                        <td><input type="text" className="form-control w-70" /></td>
                                                        <td><input type="text" className="form-control w-70" /></td>
                                                        <td><input type="text" className="form-control w-70" /></td>
                                                    </tr>
                                                </tbody></table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Accordion>

                        <br />
                        {/* BREAK HOURS */}
                        <Accordion id={3} title="Break Hours">
                            <div className="ss-table table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Event</th>
                                            <th>Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Paid Break after how many hrs?</td>
                                            <td><input type="text" className="form-control w-55" /></td>
                                        </tr>
                                        <tr>
                                            <td>Break Duration in minutes</td>
                                            <td><input type="text" className="form-control w-55" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Accordion>

                        <br />
                        {/* SHIFT MINIMUM HOURS */}
                        <Accordion id={4} title="Shift Minimum Hours">
                            <div className="ss-table table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Staff Shift</th>
                                            <th>Min Hrs.</th>
                                            <th>Max Hrs.</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Cafe</td>
                                            <td><input type="text" className="form-control w-55" /></td>
                                            <td><input type="text" className="form-control w-55" /></td>
                                        </tr>
                                        <tr>
                                            <td>Front Desk</td>
                                            <td><input type="text" className="form-control w-55" /></td>
                                            <td><input type="text" className="form-control w-55" /></td>
                                        </tr>
                                        <tr>
                                            <td>Jump Patrol</td>
                                            <td><input type="text" className="form-control w-55" /></td>
                                            <td><input type="text" className="form-control w-55" /></td>
                                        </tr>
                                        <tr>
                                            <td>Lead</td>
                                            <td><input type="text" className="form-control w-55" /></td>
                                            <td><input type="text" className="form-control w-55" /></td>
                                        </tr>
                                        <tr>
                                            <td>Manager</td>
                                            <td><input type="text" className="form-control w-55" /></td>
                                            <td><input type="text" className="form-control w-55" /></td>
                                        </tr>
                                        <tr>
                                            <td>Park Assistant</td>
                                            <td><input type="text" className="form-control w-55" /></td>
                                            <td><input type="text" className="form-control w-55" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Accordion>

                        <br />
                        {/* STAFF COUNT */}
                        <Accordion id={5} title="Staff Count (Enter Minimum staff count required based on jumpers)">
                            <div className="ss-table table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th># Jumpers</th>
                                            <th>Cafe</th>
                                            <th>Front Desk</th>
                                            <th>Jump Patrol</th>
                                            <th>Lead</th>
                                            <th>Manager</th>
                                            <th>Park Assistant</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>0-14</td>
                                            <td><input type="text" className="form-control w-55" /></td>
                                            <td><input type="text" className="form-control w-55" /></td>
                                            <td><input type="text" className="form-control w-55" /></td>
                                            <td><input type="text" className="form-control w-55" /></td>
                                            <td><input type="text" className="form-control w-55" /></td>
                                            <td><input type="text" className="form-control w-55" /></td>
                                        </tr>
                                        <tr>
                                            <td>15-30</td>
                                            <td><input type="text" className="form-control w-55" /></td>
                                            <td><input type="text" className="form-control w-55" /></td>
                                            <td><input type="text" className="form-control w-55" /></td>
                                            <td><input type="text" className="form-control w-55" /></td>
                                            <td><input type="text" className="form-control w-55" /></td>
                                            <td><input type="text" className="form-control w-55" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Accordion>

                        <br />
                        {/* MINIMUM REQUIRED STAFF */}
                        <Accordion id={6} title="Minimum staff required in opening/closing shift">
                            <div className="ss-table table-responsive mb-3">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Day of Week</th>
                                            <th>Cafe</th>
                                            <th>Front Desk</th>
                                            <th>Jump Patrol</th>
                                            <th>Lead</th>
                                            <th>Manager</th>
                                            <th>Park Assistant</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="fw-600" colspan="7">Default</td>
                                        </tr>
                                        <tr>
                                            <td>Opening Shift</td>
                                            <td><input type="text" className="form-control w-55" /></td>
                                            <td><input type="text" className="form-control w-55" /></td>
                                            <td><input type="text" className="form-control w-55" /></td>
                                            <td><input type="text" className="form-control w-55" /></td>
                                            <td><input type="text" className="form-control w-55" /></td>
                                            <td><input type="text" className="form-control w-55" /></td>
                                        </tr>
                                        <tr>
                                            <td>Closing Shift</td>
                                            <td><input type="text" className="form-control w-55" /></td>
                                            <td><input type="text" className="form-control w-55" /></td>
                                            <td><input type="text" className="form-control w-55" /></td>
                                            <td><input type="text" className="form-control w-55" /></td>
                                            <td><input type="text" className="form-control w-55" /></td>
                                            <td><input type="text" className="form-control w-55" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="row">
                                <div className="col-12 text-md-end mb-3">
                                    <Link className="ss_btn">Add Field</Link>
                                </div>
                                <div className="col-md-12">
                                    <div className="ss-table table-responsive mb-3">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Day of Week</th>
                                                    <th>Cafe</th>
                                                    <th>Front Desk</th>
                                                    <th>Jump Patrol</th>
                                                    <th>Lead</th>
                                                    <th>Manager</th>
                                                    <th>Park Assistant
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <select className="form-select w-150" aria-label="Default select example">
                                                            <option value="1" selected="">Saturday</option>
                                                            <option value="2">Sunday</option>
                                                            <option value="3">Monday</option>
                                                        </select>
                                                    </td>
                                                    <td><input type="text" className="form-control w-55" /></td>
                                                    <td><input type="text" className="form-control w-55" /></td>
                                                    <td><input type="text" className="form-control w-55" /></td>
                                                    <td><input type="text" className="form-control w-55" /></td>
                                                    <td><input type="text" className="form-control w-55" /></td>
                                                    <td><input type="text" className="form-control w-55" /></td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <select className="form-select w-150" aria-label="Default select example">
                                                            <option value="1">Saturday</option>
                                                            <option value="2" selected="">Sunday</option>
                                                            <option value="3">Monday</option>
                                                        </select>
                                                    </td>
                                                    <td><input type="text" className="form-control w-55" /></td>
                                                    <td><input type="text" className="form-control w-55" /></td>
                                                    <td><input type="text" className="form-control w-55" /></td>
                                                    <td><input type="text" className="form-control w-55" /></td>
                                                    <td><input type="text" className="form-control w-55" /></td>
                                                    <td><input type="text" className="form-control w-55" /></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </Accordion>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

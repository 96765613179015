import React, { useEffect, useState } from 'react'
import Accordion from '../../../components/Accordion'
import { Link } from 'react-router-dom'
import { useRequest } from '../../../utils/Requests'
import { SHIFTCUSTOMIZATION } from '../../../utils/Endpoints';
import { Skeleton } from '@mui/material';

export default function ShiftCustomizing({currentLocation, weekNumber, year, filterClicked, setFilterClicked, loading, setLoading}) {
    const apiRequest = useRequest();
    const [customShifts, setCustomShifts] = useState([]);

    useEffect(() => {
        const fetchRecords = async () => {
            let data = { client_id:currentLocation };
            const response = await apiRequest({url: SHIFTCUSTOMIZATION, method: "post", data});
            setCustomShifts(response?.data);
            setLoading(false);

            console.log("response", response)
        };
    
        if (filterClicked && currentLocation && weekNumber !== null && year !== null) {
            fetchRecords();
            setFilterClicked(false);
        }
      }, [filterClicked, currentLocation, weekNumber, year, setFilterClicked, apiRequest, setLoading])
  return (
    <div className="tab-pane fade active show" id="staffSetting-tab-pane" role="tabpanel" aria-labelledby="staffSetting-tab" tabIndex="0">
        <div className="card border-0 boxShadow">
            <div className="card-body">
                <div className="row">
                    <div className="col-md-12 mb-3 text-md-end">
                        <Link className="ss_btn">Save Setting</Link>
                    </div>
                    <div className="col-md-12 mb-3">

                    {loading ? <Skeleton variant="rectangular" width="100%" height={400} className="skeleton-custom" />
              
                    :

                        <Accordion id={1} title="Shift Customization" key={1}>
                            <div className="ss-table table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Staff Shift</th>
                                            <th>Time Add Before Shift(In Minutes)</th>
                                            <th>Time Add Before Shift(In Minutes)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        
                                        {customShifts.map(shift => (
                                            <tr>
                                                <td>{shift?.position}</td>
                                                <td>
                                                    <div className="input-group w-150">
                                                        <input type="text" className="form-control" placeholder="0" value={shift.timeBefore} aria-label="0" aria-describedby="basic-addon1" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="input-group w-150">
                                                        <input type="text" className="form-control" placeholder="0" value={shift.timeAfter} aria-label="0" aria-describedby="basic-addon1" />
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                        
                                    </tbody>
                                </table>
                            </div>
                        </Accordion>

                    }
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

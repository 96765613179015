import React, { useEffect, useState } from 'react'
import Accordion from '../../../components/Accordion'
import { Link } from 'react-router-dom'
import { useRequest } from '../../../utils/Requests';
import { LISTSTAFFSETTINGS } from '../../../utils/Endpoints';
import { Skeleton } from '@mui/material';
import FormDropdown from '../../../components/FormDropdown'

export default function StaffSettings({currentLocation, weekNumber, year, filterClicked, setFilterClicked, loading, setLoading}) {
    const apiRequest = useRequest();
    const [payRateList, setPayRateList] = useState([]);
    const [modelParam, setModelParam] = useState([]);
    

    useEffect(() => {
        const fetchRecords = async () => {
          let data = { client_id:currentLocation };
          const response = await apiRequest({url: LISTSTAFFSETTINGS, method: "post", data});
          setPayRateList(response?.data?.payRateListParamResult);
          setModelParam([...response?.data?.modelParamResult, {id:0, label:"Do Not Map", value:"N/A"}]);
          setLoading(false);

          console.log("response", response)
        };
    
        if (filterClicked && currentLocation && weekNumber !== null && year !== null) {
          fetchRecords();
          setFilterClicked(false);
        }
      }, [filterClicked, currentLocation, weekNumber, year, setFilterClicked, apiRequest, setLoading])

  return (
    <div className="tab-pane fade active show" id="staffSetting-tab-pane" role="tabpanel" aria-labelledby="staffSetting-tab" tabIndex="0">
        <div className="card border-0 boxShadow">
            <div className="card-body">
                <div className="row">
                    <div className="col-md-12 mb-3 text-md-end">
                        <Link className="ss_btn">Save Setting</Link>
                    </div>
                    <div className="col-md-12 mb-3">
                    {loading ? <Skeleton variant="rectangular" width="100%" height={400} className="skeleton-custom" />
              
                    :
                        <Accordion id="hourly-pay-rate" title="Hourly Pay Rate">
                            <div className="ss-table table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Staff Shift</th>
                                            <th>Assign As</th>
                                            <th>Pay Rate</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    
                                        {payRateList?.map((payRate, index) => (
                                            <tr key={index}>
                                                <td>{payRate?.position}</td>
                                                <td>
                                                    <FormDropdown name="modelParam" options={modelParam} default_value={payRate?.listParam?.position_model} aria-label="Default select example" classnm="form-select w-150" />
                                                </td>
                                                <td>
                                                    <div className="input-group w-150">
                                                        <span className="input-group-text fw-600 bg-transparent fs-12" id="basic-addon1">$</span>
                                                        <input type="text" name='pay_rate' value={payRate?.listParam?.payRate} className="form-control"  aria-label="Pay Rate" aria-describedby="basic-addon1" />
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    
                                    </tbody>
                                </table>
                            </div>
                        </Accordion>

                    }
                    </div>

                </div>
            </div>
        </div>
    </div>
  )
}
